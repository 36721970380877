<template>
  <pessoa-aba :loading="loading">
    <div v-if="!loading" class="form-AtividadeLigar">
      <div class="item-campo">
        <label>Ligar para</label>
        <div>
          <div class="u-iconCont"><i class="fas fa-user"></i></div>
          <select v-model="phone" id="call_number">
            <option v-for="(phone, i) in phoneNumbers" :value="phone.phoneNumber"
                    :key="'phone-call-' + i">{{phone.name}} - {{ phone.phoneNumber }}
            </option>
          </select>
          <button @click="copySelectedPhoneToClipboard">
            <i class="far fa-copy"></i>
            <u-tooltip content-class="bg-indigo" :offset="[5, 5]">
              Copiar número
            </u-tooltip>
          </button>
        </div>
      </div>

      <div class="item-campo">
        <label>Método de ligação</label>

        <div class="modo-ligacao">
          <u-btn :class="{active: mode === 'computer'}" @click="() => mode = 'computer'" no-caps>Computador</u-btn>
          <u-btn :class="{active: mode === 'phone'}" @click="() => mode = 'phone'" no-caps>Telefone</u-btn>
        </div>
      </div>

      <div class="item-campo">
        <label>
          <div>
            <input v-model="callType" type="radio" name="callType[]" :value="1">
          </div>
          <div>
            <span>Ligar com um aplicativo de ligação</span>
            <small>Use o software <a @click="$router.push({name: 'config.caller', hash: '#pessoal'})">{{ callerConfigLabel }}</a> instalado em seu computador</small>
          </div>
        </label>

        <label>
          <div>
            <input v-model="callType" type="radio" name="callType[]" :value="2">
          </div>
          <div>
            <span>Ligar com o Caller</span>
            <small>Gravações e registro de chamada; consome créditos do Caller</small>
          </div>
        </label>
      </div>

      <div v-if="callType === 2 || disable" class="mgs-integration blue">
        Você não possui o Caller integrado. Você pode ativar o Caller apenas através do suporte ao cliente.<br>
        <a>Peça informações para ativar o Caller</a>
      </div>
      <div v-else class="mgs-integration">
        <strong>Integrar com aplicativos de chamadas</strong>
        <span class="btn-close"><i class="fas fa-times"></i></span>
        <p>Use aplicativos como kivie, Aircall ou Justcall para fazer chamadas com seus leads e clientes.</p>
        <a>Explorar marketplace <i class="fas fa-arrow-up"></i></a>
      </div>
    </div>

    <div class="footerBox">
      <u-btn @click="call" no-caps :disable="disable" class="btnContato">Iniciar chamada</u-btn>
    </div>
  </pessoa-aba>
</template>

<script>
import PessoaAba from "@/components/pessoa/components/include/pessoa/apps/Aba"
import AppMixin from "@/components/pessoa/components/include/pessoa/apps/appMixin"
import CopyToClipboard from "@/utils/copy-to-clipboard"
import {UTooltip} from "uloc-vue"
import {getPublicConfig} from "@/domain/globalconfig/services"

export default {
  name: "PessoaLigar",
  mixins: [AppMixin],
  components: {PessoaAba, UTooltip},
  data() {
    return {
      loading: true,
      phone: null,
      callType: 1,
      mode: 'computer',
      callerConfigApp: null
    }
  },
  mounted() {
    this.loading = true
    getPublicConfig(['apps.caller'])
        .then((response) => {
          this.callerConfigApp = response.data['apps.caller']
          console.log(this.callerConfigApp)
          this.$nextTick(() => {
            this.loading = false
          })
        })
        .catch((error) => {
          this.loading = false
          this.alertApiError(error)
        })
  },
  computed: {
    phoneNumbers () {
      let contatos = []
      this.appContainer.processo.contatos && this.appContainer.processo.contatos.map(c => {
        // @TODO: <RAPHAEL> Verificar se c.contato é um número, se for, fazer o push
        contatos.push({
          name: c.pessoa.name + ' - Contato',
          phoneNumber: c.contato
        })
      })
      this.appContainer.processo.partes && this.appContainer.processo.partes.map(c => {
        // @TODO: <RAPHAEL> Verificar se c.contato é um número, se for, fazer o push
        if (c.pessoa && c.pessoa.phoneNumbers){
          c.pessoa.phoneNumbers.map(phone => {
            contatos.push({
              name: c.pessoa.name +  ' - ' + (c.tipo ? c.tipo.nome : ''),
              phoneNumber: phone.phoneNumber
            })
          })
        }
      })
      if (this.appContainer.processo.cliente) {
        const cliente = this.appContainer.processo.cliente
        if (cliente && cliente.phoneNumbers){
          cliente.phoneNumbers.map(phone => {
            contatos.push({
              name: cliente.name +  ' - Cliente',
              phoneNumber: phone.phoneNumber
            })
          })
        }
      }
      if (this.appContainer.processo.responsavel) {
        const responsavel = this.appContainer.processo.responsavel
        if (responsavel && responsavel.phoneNumbers){
          responsavel.phoneNumbers.map(phone => {
            contatos.push({
              name: responsavel.name +  ' - Responsável',
              phoneNumber: phone.phoneNumber
            })
          })
        }
      }
      return contatos
    },
    disable() {
      let d = false
      if (this.callType === 2) {
        d = true
      }
      if (this.mode === 'phone') {
        d = true
      }
      return d
    },
    callerConfig () {
      return this.callerConfigApp || {}
    },
    callerConfigLabel () {
      let label = null
      console.log('PARSE', this.callerConfig.extra)
      const parameters = JSON.parse(this.callerConfig.extra)
      parameters.options.map(o => {
        if (o.value == this.callerConfig.value) {
          label = o.label
        }
        return o
      })
      return label
    }
  },
  methods: {
    copySelectedPhoneToClipboard() {
      let numero = this.phone
      CopyToClipboard(numero)
          .then(() => {
            this.$uloc.notify({
              color: 'black',
              message: `Número copiado!`,
              position: 'bottom-left',
            })
          })
          .catch(() => {
            this.$uloc.notify({
              color: 'negative',
              message: `Erro ao tentar copiar o número!`,
              position: 'bottom-left',
            })
          })
    },
    call () {
      if (this.callType === 1 && this.mode === 'computer') {
        const link = document.createElement('a')
        link.href = this.callerConfig.value.replace('[number]', this.phone)
        document.body.appendChild(link)
        link.click();
        window.setTimeout(() => {
          document.body.removeChild(link)
        }, 100)
      }
      return
    }
  }
}
</script>
