<template>
  <u-dialog
      v-model="visible"
      @ok="confirm"
      @cancel="resetAlteracao"
  >
    <span slot="title">Marcar como perdido</span>

    <!--<span slot="message">What is your superhero of choice?</span>-->

    <div slot="body" style="width: 600px;">
      <form @submit.prevent="confirm"></form>
      <u-field
          label="Motivo da perda*"
          :label-width="3"
      >
        <u-input ref="defInput" autofocus v-model="motivoPerda" @keydown.prevent.enter="confirm" class="no-shortkey" required/>
      </u-field>
      
    </div>

    <template slot="buttons" slot-scope="props">
      <u-btn flat typecolor="grey" label="Cancelar" @click="props.cancel" />
      <u-btn color="red" :disabled="!!!motivoPerda" :loading="isLoading" label="Marcar como perdido" @click="confirm" />
    </template>
  </u-dialog>
</template>

<script>
import {UDialog, UField, UInput} from 'uloc-vue'
import { ganharPerder } from '@/domain/negocio/services/negocio'

export default {
name: 'MotivoPerda',
components: {UDialog, UInput, UField},
props: ['negocio'],
data () {
  return {
    visible: false,
    negocioID: null,
    motivoPerda: null,
    isLoading: false
  }
},
methods: {
  digitarMotivo () {
      this.visible = true
      window.setTimeout(() => {
        this.$nextTick(() => {
          this.$refs.defInput.focus()
        })
      }, 400)
    },
  confirm () {
    this.$uloc.loading.show()
    ganharPerder(this.negocio, JSON.stringify({ganho: false, motivoPerda: this.motivoPerda}))
      .then(response => {
        this.resetAlteracao()
      })
      .catch(error => {
        this.alertApiError(error)
        this.resetAlteracao()

      })
  },
  resetAlteracao () {
      this.$uloc.loading.hide()
      this.visible = false
      this.motivoPerda = null
    }
  
}
}
</script>
