<template>
  <div class="box-contato box-actions-ativo">
    <div class="header-actions">
      <ul class="nav-aba">
        <li @click="openApp(app)" v-for="app in apps" :key="app.name"><a :class="{ativo: active === app.name}"><i :class="app.icon"></i> {{ app.label }}</a></li>

  <!--      <span><i class="fas fa-times"></i></span>-->
      </ul>
    </div>
  </div>
</template>

<script>
import {processoApps2} from "@/components/processos/components/processos/include/apps/appList2"
export default {
  name: "ProcessoMenu",
  props: ['active'],
  computed: {
    apps () {
      return processoApps2
    }
  },
  methods: {
    openApp (app) {
      this.$emit('changeApp', app.name)
    }
  }
}
</script>
