<template>
  <div class="mod-pessoa mod-processo" v-if="!loading">
    <negocio-header :load="load" ref="header" :negocio="negocio" :funil="funil" />
    <!-- <processo-menu :active="app" @changeApp="changeApp" /> -->
     <div v-if="app === 'principal'" class="cont-allBox">
      <!-- <div class="f-left"> -->
        <!-- <processo-detalhes @update="(p) => processo = p" :processo="processo" /> -->
        <!-- <processo-partes @update="(p) => processo = p" :processo="processo" /> -->
        <!-- <negocio-detalhes @update="(p) => negocio = p" :negocio="negocio" /> -->
        <!-- <contato-processo @update="(p) => processo = p" :processo="processo" /> -->
      <!-- </div> -->

      <div class="f-right" >
        <negocio-abas :negocio="negocio" />

        <negocio-historico :negocio="negocio" />
      </div>
    </div>
    <component v-else v-bind:is="currentTabComponent" :processo="processo" :class="processoApps2[currentTabComponent].class" :parse-props="parseProps(processoApps2[currentTabComponent])"></component>
  </div>
  <div class="flex flex-center h-full" v-else>
    <sl-loading />
  </div>
</template>

<script>

import {find, criarNota, showNota, deleteNota, deleteArquivo} from '@/domain/negocio/services/negocio'
import { find as findFunil } from "@/domain/negocio/services/funil"
import SlLoading from "@/components/layout/components/Loading"
import NegocioHeader from "@/components/negocio/components/includes/Header"
import NegocioAbas from "@/components/negocio/components/includes/NegocioAbas"
import NegocioHistorico from "@/components/negocio/components/includes/Historico"
import ProcessoMenu from "@/components/negocio/components/includes/Menu"

import {processoApps2} from "@/components/negocio/components/includes/apps/appList2"
import Vue from "vue"

for (let app in processoApps2) {
  Vue.component(processoApps2[app].name, processoApps2[app].component)
}

export default {
  provide: function () {
    return {
      appContainer: this
    }
  },
  name: "Pessoa",
  components: {
    ProcessoMenu,
    NegocioHistorico,
    NegocioAbas,
    // ContatoProcesso,
    // NegocioDetalhes,
    // ProcessoPartes,
    // ProcessoDetalhes,
    NegocioHeader,
    SlLoading
  },
  data () {
    return {
      negocio: null,
      funil: null,
      funis: null,
      loading: true,
      app: 'principal',
      processoApps2
    }
  },
  mounted() {
    this.load(this.$route.params.id)
  },
  computed: {
    me () {
      return this.$uloc.auth.session.user.person
    },
    intercept () {
      return {
        criarNota: (data) => {
          return criarNota(this.negocio, data)
        },
        showNota: (data) => {
          return showNota(this.negocio, data)
        },
        deleteNota: (data) => {
          return deleteNota(this.negocio, data)
        },
        downloadArquivo: (a, b) => {
          return downloadArquivoProcesso(this.negocio, a, b)
        },
        deleteArquivo: (data) => {
          return deleteArquivo(this.negocio, data)
        },
      }
    },
    currentTabComponent: function () {
      return this.app
    }
  },
  methods: {
    load(id) {
      let funilId = this.$route.params.funil
      if (typeof id === 'undefined') {
        id = this.$route.params.id
      }
      this.loading = true
      find(id)
        .then(({data}) => {
          this.negocio = data
          this.loadFunil(data.funil.id)
          console.log(data)
          this.$nextTick(() => {
            if (this.$route.query.new) {
              this.$refs.header.atualizarNegocio()
            }
          })
        })
        .catch(error => {
          this.loading = false
          this.alertApiError(error)
        })

    },
    loadFunil(funilId) {
      findFunil(funilId)
        .then(response => {
            this.funil = response.data
            this.$nextTick(() => {
              this.loading = false
            })
        })
        .catch(error => {
          this.loading = false
          this.$uloc.loading.hide()
          this.alertApiError(error)
        })
    },

    atualizaProdutividade (produtividade) {
      this.negocio.produtividade = produtividade
    },
    changeApp (name) {
      console.log('Change app to ', name)
      this.app = name
    },
    parseProps (app) {
      const props = {}
      if (app.parseProps) {
        Object.assign(props, app.parseProps)
      }
      props.relationEntity = 'negocio'
      props.relationEntityId = this.negocio.id
      return props
    },

  }
}
</script>
