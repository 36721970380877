<template>
  <div>
    <tarefas class="task-layout-inline" layout="inline" relacao-entidade="negocio" :relacao-entidade-id="parseProps.negocio.id" />
  </div>
</template>

<script>
import Tarefas from "@/components/sltarefas/components/ListaTarefas"

export default {
  name: "Facilities",
  props: ['parseProps'],
  mounted() {
    console.log(this.parseProps)
  },
  data () {
    return {
    }
  },
  components: {
    Tarefas
  }
}
</script>
