export const processoApps2 = {
    principal: {
        name: 'principal',
        component: null,
        label: 'Principal',
        icon: 'far fa-list-alt',
        parseProps: {}
    },
    recursos: {
        name: 'recursos',
        component: () => import("@/components/processos/components/processos/include/apps/RecursosCP"),
        label: 'Recursos / Carta Precatória',
        class: 'cont-allBox',
        icon: 'far fa-book',
        parseProps: {}
    },
    movimentacao: {
        name: 'movimentacao',
        component: () => import("@/components/processos/components/processos/include/apps/Movimentacao"),
        class: 'cont-allBox',
        label: 'Movimentação / Push',
        icon: 'far fa-list-alt',
        parseProps: {}
    },
    financeiro: {
        name: 'financeiro',
        component: () => import("@/components/financeiro/components/Contas"),
        label: 'Financeiro',
        class: 'wrapper-neg',
        icon: 'far fa-money-check',
        parseProps: {
            embedComponent: true
        }
    },
    bens: {
        name: 'bens',
        component: () => import("@/components/bem/components/ListaBens"),
        label: 'Bens',
        icon: 'far fa-file-invoice-dollar',
        class: 'wrapper-neg',
        parseProps: {}
    },
    envolvidos: {
        name: 'envolvidos',
        component: () => import("@/components/processos/components/processos/include/apps/Envolvidos"),
        label: 'Envolvidos',
        icon: 'far fa-users',
        class: 'wrapper-neg',
        parseProps: {}
    },
    etapas: {
        name: 'etapas',
        component: () => import("@/components/processos/components/processos/include/apps/Etapas"),
        label: 'Acompanhamento',
        icon: 'far fa-hourglass',
        class: 'wrapper-neg',
        parseProps: {}
    },
}
