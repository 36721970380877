<template>
  <pessoa-box class="c-fullHeader negocio-box">
    <div class="full-bg">
      <div class="row-top">

        <div class="c-pessoa">
          <h1>{{ negocio.titulo }}</h1>
          <div class="show-pessoa">
            <div class="m-l-sm">
              <div class="def-box box-full box-blue">
                <span class="font-bold no-select cursor-pointer">Negócio</span>
                <!--<i class="fas fa-tag"></i>-->
              </div>
            </div>
          </div>
        </div>

        <div class="right">
          <div class="c-user">
            <negocio-responsavel ref="clienteComponent" :pessoa="negocio.pessoa" :negocio="negocio" type="pessoa"
              label="Pessoa" />
          </div><!-- Show User -->
          <div class="c-user">
            <negocio-responsavel ref="responsavelComponent" :pessoa="negocio.organizacao" :negocio="negocio"
              type="organizacao" label="Organização" />
          </div><!-- Show User -->
          <div class="c-user">

          </div><!-- Show User -->

          <div class="c-actions">
            <u-btn v-if="(negocio.ganho === null || negocio.ganho === true)" :disabled="typeof negocio.ganho === 'boolean'" @click="ganharNegocio(true)" no-caps class="btnContato no-border-radius-right">
               Ganho
            </u-btn>
            <u-btn  v-if="(negocio.ganho === null || negocio.ganho === false)" :disabled="typeof negocio.ganho === 'boolean'" @click="() => ganharNegocio(false)"  no-caps class="btnContato no-border-radius-right m-l-xs" style="background-color: red;">
              Perdido
            </u-btn>
            <u-btn v-if="typeof negocio.ganho === 'boolean'" @click="() => ganharNegocio(null)"  no-caps class="btn-action no-border-radius-right m-l" style="width: auto !important; padding: 0 18px;font-weight: bold; font-size: 12px;">
              Reabrir
            </u-btn>

            <u-btn icon="ellipsis-h" icon-type="fa" class="btn-action">
              <u-popover class="window-context-menu menu-profile min" anchor="bottom left" self="top left"
                :offset="[0, 5]">
                <div>
                  <ul>
                    <menu-options-item @click="windowNegocio(funil, negocio.id)" label="Editar Negócio" close />
                  </ul>
                  <div class="diviser"></div>
                  <ul>
                    <menu-options-item disabled label="Gerenciar seções da barra lateral" close /> <!-- @TODO -->
                  </ul>
                </div>
              </u-popover>

            </u-btn>

          </div><!-- END Actions -->
        </div>
      </div>
      <div>
        <div class="c-pessoa valor">
          <h1>R${{ negocio.valor | moeda }}</h1>
        </div>
        <div class="c-pessoa">

          <etapas-funil :loading="etapaFunilLoading" :etapas="funil.etapas" :value="negocio.funilEtapa.id"
            @update="((e) => updateEtapa(e))" />
        </div>

      </div>
      <motivo-perda ref="motivoperda" :negocio="negocio"/>
    </div>
  </pessoa-box><!-- END Full Header -->

</template>

<script>
import NegocioMixin from './mixin'
import PessoaBox from "@/components/pessoa/components/include/pessoa/Box"
import { UPopover } from "uloc-vue"
import MenuOptionsItem from '@/components/layout/context-menu/context-window-options-item'
import NegocioResponsavel from "@/components/negocio/components/includes/Responsavel"
import MotivoPerda from "@/components/negocio/components/includes/MotivoPerda"
import { TIPO_PROCESSO_FISICO } from "@/domain/processos/helpers/tipo-processo";
import { callRobo } from "@/domain/negocio/services/robo";
import novoNegocio from "components/negocio/windows/novoNegocio"
import EtapasFunil from "../inputs/EtapasFunil.vue"
import { updateFunilEtapaFromNegocio, ganharPerder } from '@/domain/negocio/services/negocio'

export default {
  name: "NegocioHeader",
  components: { NegocioResponsavel, PessoaBox, MenuOptionsItem, UPopover, EtapasFunil, MotivoPerda },
  mixins: [NegocioMixin],
  inject: ['appContainer'],
  props: ['funil', 'negocios'],
  data() {
    return {
      atualizandoNegocio: false,
      etapaFunilLoading: false
    }
  },
  computed: {
  },
  methods: {
    loadContainerNegocio () {
      this.appContainer.load()
    },
    windowNegocio: novoNegocio,
    atualizarNegocio() {
      if (this.atualizandoNegocio) return
      this.atualizandoNegocio = true
        .then(() => {
          this.atualizandoNegocio = false
          this.$router.push({ name: 'negocio', params: { id: this.negocio.id } })
          setTimeout(() => {
            this.loadContainerNegocio()
          }, 1000)
        })
        .catch((err) => {
          this.alertApiError(err)
          this.atualizandoNegocio = false
        })
    },
    updateEtapa(etapa) {
      this.negocio.funilEtapa.id = etapa
      this.etapaFunilLoading = true
      updateFunilEtapaFromNegocio(this.negocio.id, etapa)
        .then(response => {
          this.etapaFunilLoading = false
          this.$emit('load', this.negocio.id)
          this.$nextTick(() => {
            this.loadingKanban = false
          })
        })
        .catch(error => {
          this.etapaFunilLoading = false
          this.alertApiError(error)
        })
    },
    ganharNegocio(isGanho) {
      if(isGanho === false) {
        this.$refs.motivoperda.digitarMotivo()
      } else {
        ganharPerder(this.negocio, JSON.stringify({ganho: isGanho}))
          .then(response => {
            this.$emit('load', this.negocio.id)
            this.$nextTick(() => {
              this.loadingKanban = false
            })
          })
          .catch(error => {
            this.alertApiError(error)
          })
      }
    }
  }
}
</script>
