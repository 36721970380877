<template>
    <div class="cui5-stage-selector cui5-stage-selector--size-m cui5-stage-selector--deal-status-active">
      <label 
        v-for="(label, index) in etapas"
        @click="$emit('update', label.id)"
        :class="'cui5-stage-selector__stage ' + (label.id === value ? 'cui5-stage-selector__stage--current' : '')"
        :title="label.nome">
        <div v-if="((label.id === value) && loading)" class="sl-loading">
          <div class="sl-loading-trail" style="width: 20px; height: 20px;">
          </div>
        </div>
      </label>
      

    </div>
</template>

<script>

export default {
  name: "EtapasFunil",
  props: ['active', 'loading', 'value', 'etapas'],
  components: {
  },
  computed: {
    selected() {
      return this.value
    }
  },
  mounted() {
    // this.load()
  },
  methods: {
    /*load() {
      list(1, 50, '')
          .then(response => {
            console.log(response.data)
            this.funils = response.data.result
          })
          .catch(error => {
            this.alertApiError(error)
          })
    },*/
  }
}
</script>
